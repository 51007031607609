// frontend/src/components/Experience/index.js

import "./Experience.css";

const Experience = () => {

    return (
        <div className="experience-container">
            <div className="experience-details">
                <div className="experience-header">
                    <h2 className="experience-title">Software Engineer</h2>
                    <p className="experience-date">2020-present</p>
                    <h3>Freelance</h3>
                </div>
                <div className="experience-body">
                    <li>Build web apps from ideation to functional minimum viable product</li>
                    <li>Produce constructive feedback and testing for potential client ideas</li>
                    <li>Deliver career and coursework guidance to university students from alma mater</li>
                </div>
            </div>

            <div className="experience-details">
                <div className="experience-header">
                    <h2 className="experience-title">Support Engineer</h2>
                    <p className="experience-date">2017-2018</p>
                    <h3> Demandbase </h3>
                </div>
                <div className="experience-body">
                    <li>Developed custom solutions for clients in the post-implementation phase</li>
                    <li>Proactively reported and fixed frontend bugs of React components</li>
                    <li>Created content that established product identity within Google Analytics </li>
                    <li id="Marketo-forms">and Marketo Forms</li>
                    <li>Certified with Account-Based Marketing and Demandbase API </li>
                </div>
            </div>

            <div className="experience-details">
                <div className="experience-header">
                    <h2 className="experience-title">Software Engineer</h2>
                    <p className="experience-date">2015-2017</p>
                    <h3> Tata Consultancy Services </h3>
                </div>
                <div className="experience-body">
                    <li>Consulted with one of the Big Four Banks and was quickly promoted to team lead</li>
                    <li>Bootstrapped SDL Tridion content management system</li>
                    <li>Collaborated with content authors to publish sensitive financial information</li>
                    <li>Reduced overall bug reports by 30%</li>
                </div>
            </div>
        </div>
    );
}

export default Experience;
