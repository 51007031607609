// frontend/src/components/Skill/index.js

import './Skill.css';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addItem } from '../../store/cartReducer';

const Skill = ({ details, id = null }) => {
    const dispatch = useDispatch();
    const { bigText, info } = details;
    const [showDetails, setShowDetails] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        dispatch(addItem(bigText));
    };

    return (
        <div className="skill-container"
            onClick={handleClick}
            onMouseEnter={() => setShowDetails(true)}
            onMouseLeave={() => setShowDetails(false)}
        >
            {!showDetails &&
                <h2 id={id}>{bigText}</h2>
            }
            {showDetails && (
                <div className="skill-info">
                    {info.map((item) => <li key={item}>{item}</li>)}
                </div>
            )}
        </div>
    );
};

export default Skill;
