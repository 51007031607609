import imgSrc from '../../images/gifs/MockupGif.gif';

const Mockup = ({hideDetails}) => {
    return (
        <div className="details-container">
            <button id="x-close" onClick={hideDetails}>X</button>
            <h2 id="details-container-header">Website Mockup</h2>
            <p id="details-container-summary">
                Often times, developers work to bring a stakeholder's vision
                to life. The design could come from modern modeling software
                or even a table napkin. For this project, I put together a
                mockup of Flickr's landing page from a general wireframe.
                <br /><br />
                The most difficult part of this project was keeping it simple yet functional
                with moving parts.
                The webpage mockup was completed primarily with HTML and CSS.
                I relied on CSS media queries to resize elements for mobile users, and
                a small script for cycling the image carousel.
                <br /><br />
                Skills: HTML, CSS, JavaScript, Media Queries, Wireframe, Responsive Mobile-First Design
                <br /><br />
                <span className="demo-gif">
                    <img src={imgSrc} alt="demo-gif"/>
                    <label>CSS Media Query Demo</label>
                </span>
            </p>
        </div>
    );
};

export default Mockup;
