import imgSrc from '../../images/gifs/ForYouPageGif.gif';

const ForYouPage = ({hideDetails}) => {
    return (
        <div className="details-container">
            <button id="x-close" onClick={hideDetails}>X</button>
            <h2 id="details-container-header">For You Page</h2>
            <p id="details-container-summary">
                The For You Page takes inspiration from TikTok's iconic
                infinite-scroll content and presents it in a chronological,
                bite-sized format.
                <br /><br />
                Users can search for their favorite content creators, which
                adds their 5 most recent videos to the view. With each addition,
                the videos are sorted amongst the ongoing list of all creators' videos.
                <br /><br />
                Skills: JavaScript, React, HTML, CSS, Redux, Thunk, Express, ProxiTok API
                <br /><br />
                <span className="demo-gif">
                    <img src={imgSrc} alt="demo-gif"/>
                    <label>For You Page Demo</label>
                </span>
            </p>
        </div>
    );
};

export default ForYouPage;
