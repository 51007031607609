import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Portfolio from "./components/Portfolio";

function App() {


  return (
    <>
      <Routes>
        <Route
          exact path="/"
          element={<Portfolio/>}
        />
        <Route
          exact path="/portfolio"
          element={<Portfolio/>}
        />
      </Routes>
    </>
  );
}

export default App;
