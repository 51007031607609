import imgSrc from '../../images/gifs/SpotifyHangmanGif.gif';

const SpotifyHangman = ({hideDetails}) => {
    return (
        <div className="details-container">
            <button id="x-close" onClick={hideDetails}>X</button>
            <h2 id="details-container-header">Spotify Hangman</h2>
            <p id="details-container-summary">
                Spotify Hangman challenges you to guess the song following a nostalgic form factor.
                <br /><br />
                Requests were made using the Spotify API to get a list of genres, a list of playlists,
                and a random track. React's useState and useEffect were helpful in updating and
                re-rendering, while Redux's useSelector allowed utilization of the song state. I implemented
                action creators to make the changes for the song, hangman guesses, and resetting.
                <br /><br />
                After playing multiple rounds, I believe the game has potential with a little more
                polish on the design and mechanics.
                <br /><br />
                Skills: Spotify REST API, JavaScript, React, HTML, CSS, Redux, Thunk, Wireframe
                <br /><br />
                <span className="demo-gif">
                    <img src={imgSrc} alt="demo-gif"/>
                    <label>Spotify Hangman Demo</label>
                </span>
            </p>
        </div>
    );
};

export default SpotifyHangman;
