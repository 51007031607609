import imgSrc from '../../images/tiles/authenticate-demo.png';

const AuthenticateMe = ({hideDetails}) => {
    return (
        <div className="details-container">
            <button id="x-close" onClick={hideDetails}>X</button>
            <h2 id="details-container-header">Authenticate Me</h2>
            <p id="details-container-summary">
                Authenticate Me is a multi-part project that puts together
                Express + React with authentication inspired by App Academy Open.
                Taking advantage of JSON Web Tokens, users remain logged into the
                application with an adjustable expiration time.
                <br /><br />
                When a new user visits, they initiate the Sign Up process. In the backend,
                a User is created with the entered username, email address, and a hashed
                password. A JWT is created to store the information and set as
                a browser cookie. When the user visits again, their token along with
                an HTTP-only cookie to protect against cross-site request forgery are
                used for authentication.
                <br /><br />
                To the user in the frontend, everything from authentication to redirection
                to their home page is seamless. Combining the process with React web components,
                the webpage can update modals often without the user even noticing. Modern sites
                truly have come a long way from having to reload the entire page to see a change!
                <br /><br />
                Skills: Express, JWT, XSRF protection, JavaScript, React, HTML, CSS, Redux, Thunk,
                Sequelize, PostgreSQL
                <br /><br />
                <span className="demo-gif">
                    <img src={imgSrc} alt="demo-login" />
                    <label>Authenticate Me Demo</label>
                </span>
            </p>
        </div>
    );
};

export default AuthenticateMe;
