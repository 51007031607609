// frontend/src/components/Cart/index.js
import './Cart.css';
import { useDispatch, useSelector } from 'react-redux';
import { checkoutCart } from '../../store/cartReducer';
import CartItem from './CartItem';

const Cart = () => {
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cartState);

    const cartItems = Object.values(cart);

    if (!cartItems || !cartItems.length){
        return (
            <div className="cart">
            No items in the cart. Start selecting items to purchase.
            </div>
        );
    };

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(checkoutCart());
        window.open("https://linkedin.com/in/markjee", "_blank");
    };

    return (
        <div className="cart">
            <ul>
                {cartItems.map(item => <CartItem key={item} item={item}/>)}
            </ul>
            <form onSubmit={onSubmit}>
                <button type="submit">Checkout</button>
            </form>
        </div>
    );
};

export default Cart;
