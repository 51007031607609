import './ProjectDetails.css';
import ForYouPage from './ForYouPage';
import Mockup from './Mockup';
import SpotifyHangman from './SpotifyHangman';
import AuthenticateMe from './AuthenticateMe';

const ProjectDetails = ({ projectName, hideDetails }) => {
    let projectDetails;

    switch(projectName){
        case "ForYouPage": {
            projectDetails = (
                <div onClick={() => hideDetails()} >
                    <ForYouPage hideDetails={() => hideDetails()}/>
                </div>
            );
            break;
        }
        case "Mockup": {
            projectDetails = (
                <div onClick={() => hideDetails()}>
                    <Mockup hideDetails={() => hideDetails()}/>
                </div>
            );
            break;
        }
        case "SpotifyHangman": {
            projectDetails = (
                <div onClick={() => hideDetails()} >
                    <SpotifyHangman hideDetails={() => hideDetails()}/>
                </div>
            );
            break;
        }
        case "AuthenticateMe": {
            projectDetails = (
                <div onClick={() => hideDetails()} >
                    <AuthenticateMe hideDetails={() => hideDetails()}/>
                </div>
            );
            break;
        }
        default: return;
    };

    return (
        <>
            {projectDetails}
        </>
    );
};

export default ProjectDetails;
