// frontend/src/components/Project/index.js
import './Project.css';
import ProjectDetails from "./ProjectDetails";
import { useState } from "react";

const Project = ({ projectName }) => {
    const [showDetails, setShowDetails] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setShowDetails(true);
    }

    return (
        <>
            <div className="project-container" id={projectName}>
                <div className="project-tile" onClick={handleClick}>
                    <span className="fa-solid fa-magnifying-glass-plus fa-beat"></span>
                </div>
                <div className="project-details">
                    {showDetails ?
                        <ProjectDetails key={projectName} projectName={projectName} hideDetails={() => setShowDetails(false)}/>
                        :
                        null
                    }
                </div>
            </div>
        </>

    );
};

export default Project;
