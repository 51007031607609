// frontend/src/components/Portfolio/index.js
import './Portfolio.css';
import Skill from "../Skill";
import Project from '../Project';
import Experience from '../Experience';
import Cart from '../Cart';
import { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

const Portfolio = () => {
    const [showCart, setShowCart] = useState(false);
    const cart = useSelector(state => state.cartState);
    useEffect(() => {
        if(Object.keys(cart).length > 0)
          setShowCart(true);
        else setShowCart(false);
    }, [cart])

    return (
        <>
        <div style={showCart ? { marginRight: '300px' } : {}}>
            <section className="intro" id="welcome-section">
                <div id="left-intro">
                    <h1>Mark Jee</h1>
                    <p>Software Engineer</p>
                    <ul id="social-media">
                        <li><a className="fa-brands fa-linkedin" href="https://linkedin.com/in/markjee" target="_blank" rel="noreferrer"><span className="screen-reader-only"/></a></li>
                        <li><a className="fa-brands fa-github" href="https://github.com/markjee" target="_blank" rel="noreferrer"> <span className="screen-reader-only"/></a></li>
                    </ul>
                </div>
                <div id="right-intro">
                    <p> Welcome to my portfolio.</p>
                    <p>
                        Interact with the projects and skills to
                        get a deeper look into what I've been up to.
                    </p>
                </div>

            </section>
            <span className="white-break" />

            <h2 className="section-title" id="projects-section">Projects</h2>
            <section className="projects">
                <Project projectName="ForYouPage" />
                <Project projectName="SpotifyHangman" />
                <Project projectName="Mockup" />
                <Project projectName="AuthenticateMe" />
            </section>
            <span className="white-break" />

            <h2 className="section-title" id="skills-section">Skills</h2>
            <h3 className="skill-title">Frontend</h3>
            <section className="skills">
                <Skill details={{ bigText: "JavaScript", info: ["ES6", "Object-oriented"]}} />
                <Skill details={{ bigText: "HTML", info: ["HTML5", "Forms/Carts", "Various Components"]}} />
                <Skill details={{ bigText: "CSS", info: ["CSS3", "Flexbox", "Media Query"]}} />
                <Skill details={{ bigText: "React", info: ["JSX","Redux", "Thunk"]}} />
                <Skill details={{ bigText: "Testing", info: ["Mocha/Chai", "Jest", "TDD/BDD"]}} />
                <Skill details={{ bigText: "UI/UX", info: ["Design", "SEO"]}} />
            </section>
            <h3 className="skill-title">Backend</h3>
            <section className="skills">
                <Skill details={{ bigText: "Database", info: ["PostgreSQL","SQLite3", "Sequelize"]}} />
                <Skill details={{ bigText: "Express", info: ["Routing", "Middleware", "Error Handling"]}} />
                <Skill details={{ bigText: "REST API", info: ["GET/POST", "PUT/DELETE", "JSON"]}} />
            </section>
            <h3 className="skill-title">Soft Skills</h3>
            <section className="skills">
                <Skill details={{ bigText: "Team Player", info: ["Team Player"]}} />
                <Skill details={{ bigText: "Communication", info: ["Communication"]}} id="communication-skill"/>
                <Skill details={{ bigText: "Leadership", info: ["Leadership"]}} />
            </section>
            <span className="white-break" />

            <h2 className="section-title" id="experience-section">Experience</h2>
            <section className="experience">
                <Experience />
            </section>
            <span className="white-break" />
        </div>
            <div
                className="sidebar"
                style={showCart ? { transform: 'translateX(-100%)' } : {}}
            >
                <div className="sidebar-header">
                    <button className="arrow-button" onClick={() => setShowCart(false)}>
                        <span className="fas fa-arrow-right" />
                    </button>
                    <h2>Skills Cart</h2>
                </div>
                <Cart />
            </div>
        </>
    );
};

export default Portfolio;
