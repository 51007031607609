const ADD_ITEM = "cart/addItem";
const REMOVE_ITEM = "cart/removeItem";
const CHECKOUT_CART = "cart/checkout";

export const addItem = (item) => {
    return {
        type: ADD_ITEM,
        item
    };
};

export const removeItem = (item) => {
    return {
        type: REMOVE_ITEM,
        item
    };
};

export const checkoutCart = () => {
    return {
        type: CHECKOUT_CART
    };
};

const initialState = {};

const cartReducer = (state = initialState, action) => {
    const { type, item } = action;

    switch(type){
        case ADD_ITEM: {
            const newCart = {...state};
            if(newCart[item]){
                return newCart;
            }

            newCart[item] = item;
            return newCart;
        }
        case REMOVE_ITEM: {
            const newCart = {...state};
            delete newCart[item];
            return newCart;
        }
        case CHECKOUT_CART: {
            return {};
        }
        default:
            return state;
    }
};

export default cartReducer;
