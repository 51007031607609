import { useDispatch } from "react-redux";
import { removeItem } from "../../store/cartReducer";

function CartItem({ item }) {
  const dispatch = useDispatch();

  return (
    <li className="cart-item">
      <div className="cart-item-header">
        {item}
      </div>
      <button
        className="cart-item-button"
        onClick={() => dispatch(removeItem(item))}
      >
        Remove
      </button>
    </li>
  );
}

export default CartItem;
